import React from "react";

export interface CheckboxProps {
  disabled?: boolean;
  name: string;
  type?: "checkbox";
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  disabled = false,
  type = "checkbox",
  onChange,
  checked,
}) => {
  return (
    <input
      id={name}
      disabled={disabled}
      className="e-block e-bg-white e-rounded e-border-gray-300 e-shadow-sm e-text-brand-orange focus:e-border-brand-orange focus:e-ring-brand-orange"
      name={name}
      type={type}
      onChange={onChange}
      defaultChecked={checked}
    />
  );
};

export default Checkbox;
