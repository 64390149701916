import { CalculationContext } from "../../../providers/CalculationProvider";
import { format } from "../../../lib/money/money";
import { PaymentType } from "../../../lib/middleware/types";
import { useContext } from "react";

function Calculator() {
  const { conceptQuote } = useContext(CalculationContext);

  const getMonthly = (): string => {
    let amount = conceptQuote.data?.monthly_sum || 0;

    return format(amount);
  };

  const getOneOff = (): string => {
    let amount = conceptQuote.data?.one_off_sum || 0;

    return format(amount);
  };
  //
  //   const groupedQuoteLines = {}
  //
  //   // Group items by group_id
  //   conceptQuote.data?.quote_lines.forEach(item => {
  //     if (item.group_id !== null && item.group_id !== undefined) {
  //       if (!groupedQuoteLines[item.group_id]) {
  //         groupedQuoteLines[item.group_id] = { ...item };
  //       } else {
  //         groupedQuoteLines[item.group_id].price += item.price;
  //         groupedQuoteLines[item.group_id].quantity += item.quantity;
  //       }
  //     }
  //   });
  //
  // // Convert grouped items back to array
  //   const groupedArray = Object.values(groupedQuoteLines);
  //
  // // Merge grouped items back to the original array
  //   const mergedArray = [...groupedArray, ...data.quote_lines.filter(item => item.group_id === null || item.group_id === undefined)];
  //
  // // Update data with the merged array
  //   data.quote_lines = mergedArray;

  return (
    <>
      <div className="e-block md:e-hidden e-space-y-4">
        {conceptQuote.data?.quote_lines.map((line, index) => {
          return (
            <ul className="e-flex e-flex-col">
              <li className="e-font-semibold">{line.description}</li>
              <li>
                <span>Aantal: </span>
                {line.quantity}
              </li>

              {line.type === PaymentType.MONTHLY ? (
                <li>
                  <span>Maandelijks: </span>
                  {format(line.price)}
                </li>
              ) : null}

              {line.type === PaymentType.ONE_OFF ? (
                <li>
                  <span>Eenmalig: </span>
                  {format(line.price)}
                </li>
              ) : null}
            </ul>
          );
        })}

        <ul className="e-flex e-flex-col e-pt-2 ">
          <li className="e-font-semibold">Totaal:</li>
          <li>
            <span>Maandelijks: </span>
            {getMonthly()}
          </li>
          <li>
            <span>Eenmalig: </span>
            {getOneOff()}
          </li>
        </ul>
      </div>

      <div className="e-sticky e-top-0 e-w-full e-overflow-auto e-hidden md:e-block">
        {conceptQuote.isFetching && <div>Berekenen...</div>}
        {!conceptQuote.isFetching && (
          <table className="e-table-auto e-w-full">
            <thead>
              <tr>
                <th className="e-px-2 e-py-1 e-border-b-0"></th>
                <th className="e-px-2 e-py-1 e-border-b-0">Aantal</th>
                <th className="e-px-2 e-py-1 e-border-b-0">Eenmalig</th>
                <th className="e-px-2 e-py-1 e-border-b-0">Per maand</th>
              </tr>
            </thead>

            <tbody>
              {conceptQuote.data?.quote_lines.map((line, index) => {
                return (
                  <tr key={`quote-line-${index}`}>
                    <td className="e-pr-2 e-py-2 e-border-b-0">
                      {line.description}
                    </td>
                    <td className="e-pr-2 e-py-2 e-border-b-0">
                      {line.quantity}
                    </td>
                    <td className="e-pr-2 e-py-2 e-border-b-0">
                      {line.type === PaymentType.ONE_OFF
                        ? format(line.price)
                        : null}
                    </td>
                    <td className="e-pr-2 e-py-2 e-border-b-0">
                      {line.type === PaymentType.MONTHLY
                        ? format(line.price)
                        : null}
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td className="e-font-bold e-pr-2 e-py-2 e-border-b-0">
                  Totaal
                </td>
                <td className="e-border-b-0"></td>
                <td className="e-font-bold e-pr-2 e-py-2 e-border-b-0">
                  {getOneOff()}
                </td>
                <td className="e-font-bold e-pr-2 e-py-2 e-border-b-0">
                  {getMonthly()}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}

export default Calculator;
