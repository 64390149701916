import {
  Base,
  CalculateResource,
  MiddlewareClient,
  ProductResource,
  QuoteResource, TranslationResource,
} from "./types";
import { Client } from "./client";
import Product from "./resources/product";
import Calculate from "./resources/calculate";
import Quote from "./resources/quote";
import Translation from "./resources/translation";

export class Middleware implements Base {
  public client: MiddlewareClient;

  // resources
  public productResource: ProductResource;
  public calculateResource: CalculateResource;
  public quoteResource: QuoteResource;
  public translationResource: TranslationResource;

  constructor(baseURL: string) {
    this.client = new Client(baseURL);
    this.productResource = new Product(this.client);
    this.calculateResource = new Calculate(this.client);
    this.quoteResource = new Quote(this.client);
    this.translationResource = new Translation(this.client);
  }
}
