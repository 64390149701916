import React, { SetStateAction } from "react";
import Input, { InputProps } from "./Input";
import { classNames } from "../utils/classNames";
import Label from "./Label";

export interface InputGroupProps extends InputProps {
  label: string;
  className?: string;
  tooltip?: string;
  helperText?: string;
  showHelperText?: boolean;
}

const InputGroup: React.FC<InputGroupProps> = ({
  className,
  label,
  name,
  type,
  disabled = false,
  placeholder,
  onChange,
  value,
  tooltip,
  helperText,
  showHelperText = false,
  ...additionalProps
}) => {
  return (
    <div className={classNames("e-w-full", className)}>
      <Label htmlFor={name} label={label} tooltip={tooltip} />

      <Input
        value={value}
        onChange={onChange}
        type={type}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        {...additionalProps}
      />

      {showHelperText && (
        <p className="e-mt-2 e-text-sm e-text-red-600" id="email-error">
          {helperText}
        </p>
      )}
    </div>
  );
};

export default InputGroup;
