import { useNavigate } from "react-router-dom";
import Panel from "../../components/Panel";
import Button from "../../components/Button";
import InputGroup from "../../components/InputGroup";
import React, { useContext, useEffect } from "react";
import {
  CalculationContext,
  CalculationFormDevice,
} from "../../providers/CalculationProvider";
import CheckboxGroup from "../../components/CheckboxGroup";
import { useQuery } from "react-query";
import { AppContext } from "../../providers/AppProvider";
import Input from "../../components/Input";
import ShortSummary from "./components/ShortSummary";
import AppLayout from "../../layout/AppLayout";
import Label from "../../components/Label";

function Calculator() {
  const navigate = useNavigate();
  const { updateCalculationForm, calculationForm, conceptQuote } =
    useContext(CalculationContext);

  const { middleware, translations } = useContext(AppContext);

  const { data, isFetching, error } = useQuery("products/list", async () => {
    const res = await middleware.productResource.list();
    return res.data;
  });

  const getDeviceByID = (
    productID: number
  ): CalculationFormDevice | undefined => {
    return calculationForm.devices.find((dev) => dev.id === productID);
  };

  const updateDeviceQuantity = (
    productID: number,
    code: string,
    quantity: number
  ) => {
    const devices = calculationForm.devices;
    let device = getDeviceByID(productID);

    if (!device) {
      devices.push({
        id: productID,
        quantity: quantity,
        code: code,
      });
    } else {
      device.quantity = quantity;
    }

    if (quantity === 0) {
      const index = devices.findIndex((dev) => dev.id === productID);
      devices.splice(index, 1);
    }

    updateCalculationForm({
      devices: devices,
    });
  };

  if (error) {
    return <div>Er is iets fout gegaan, probeer het later nogmaals</div>;
  }

  const existingNumbersTooltip = translations?.find(
    (t) => t.key === "tooltip_existing_numbers"
  );
  const newNumbersTooltip = translations?.find(
    (t) => t.key === "tooltip_new_numbers"
  );
  const amountUsersTooltip = translations?.find(
    (t) => t.key === "tooltip_amount_users"
  );
  const amountMobileUsersTooltip = translations?.find(
    (t) => t.key === "tooltip_amount_mobile_users"
  );
  const workFromHomeTooltip = translations?.find(
    (t) => t.key === "tooltip_work_from_home"
  );
  const includeInstallationTooltip = translations?.find(
    (t) => t.key === "tooltip_include_installation"
  );

  const messages: string[] = [];

  const amountOfDevices = calculationForm.devices.reduce(
    (acc, device) => acc + device.quantity,
    0
  );
  if (amountOfDevices > calculationForm.users) {
    messages.push(
      "Het aantal toestellen is meer dan het aantal accounts, graag het aantal accounts aanpassen"
    );
  }

  const wirelessDevices = calculationForm.devices
    .filter((device) => device.code === "008")
    .reduce((acc, device) => acc + device.quantity, 0);

  if (wirelessDevices > 5) {
    messages.push(
      "Het aantal draadloze toestellen is meer dan 5, wellicht is een DECT systeem voordeliger. Vraag vrijblijvend advies aan"
    );
  }

  const existingDevices = calculationForm.devices
    .filter((device) => device.code === "007")
    .reduce((acc, device) => acc + device.quantity, 0);

  if (existingDevices > 0) {
    messages.push(
      "Je hebt gekozen voor bestaande toestellen. Laat vooraf de compatibiliteit door ons controleren"
    );
  }

  return (
    <AppLayout>
      <Panel title="Calculatietool">
        <div className="e-grid e-grid-cols-12">
          <div className="e-col-span-12 lg:e-col-span-6 e-space-y-6">
            <InputGroup
              label="Hoeveel betaande nummers wilt u meenemen?"
              name="existing_numbers"
              placeholder={""}
              type="number"
              tooltip={existingNumbersTooltip?.value}
              onChange={(e) =>
                updateCalculationForm({
                  existing_numbers: isNaN(e.target.valueAsNumber)
                    ? 0
                    : e.target.valueAsNumber,
                })
              }
              defaultValue={null}
              min={0}
              max={9999}
            />
            <InputGroup
              label="Hoeveel nieuwe nummers wilt u?"
              name="new_numbers"
              placeholder={""}
              type="number"
              tooltip={newNumbersTooltip?.value}
              onChange={(e) =>
                updateCalculationForm({
                  new_numbers: isNaN(e.target.valueAsNumber)
                    ? 0
                    : e.target.valueAsNumber,
                })
              }
              defaultValue={null}
              min={0}
              max={9999}
            />

            <InputGroup
              label="Hoeveel gebruikers?"
              name="users"
              placeholder={""}
              type="number"
              tooltip={amountUsersTooltip?.value}
              onChange={(e) =>
                updateCalculationForm({
                  users: isNaN(e.target.valueAsNumber)
                    ? 0
                    : e.target.valueAsNumber,
                })
              }
              defaultValue={null}
              min={0}
              max={9999}
            />
            <InputGroup
              label="Mobiele gebruikers?"
              name="mobile_users"
              placeholder={""}
              type="number"
              tooltip={amountMobileUsersTooltip?.value}
              onChange={(e) =>
                updateCalculationForm({
                  mobile_users: isNaN(e.target.valueAsNumber)
                    ? 0
                    : e.target.valueAsNumber,
                })
              }
              disabled={calculationForm.work_from_home}
              defaultValue={null}
              min={0}
              max={9999}
            />
            <CheckboxGroup
              label="Thuis werken middels MS teams of bel applicatie voor PC, laptop en mobiel?"
              name="work_from_home"
              tooltip={workFromHomeTooltip?.value}
              onChange={(e) => {
                updateCalculationForm({
                  work_from_home: e.target.checked,
                });

                updateCalculationForm({
                  mobile_users: 0,
                });
              }}
              checked={calculationForm.work_from_home}
            />

            <div className="e-grid-cols-12 e-hidden lg:e-grid">
              <div className="e-col-span-6">
                <span className="e-text-sm e-font-semibold e-text-gray-900">
                  Product
                </span>
              </div>

              <div className="e-col-span-6">
                <span className="e-text-sm e-font-semibold e-text-gray-900">
                  Aantal
                </span>
              </div>
            </div>

            <div className="e-space-y-4">
              {data?.map((product) => (
                <div
                  className="e-w-full e-flex e-flex-col lg:e-flex-row"
                  key={product.id}
                >
                  <div className="e-w-full lg:e-w-1/2 e-text-sm e-flex e-items-center font-medium text-gray-900 e-py-2">
                    <Label
                      htmlFor={`product_${product.id}`}
                      tooltip={product.description}
                      label={`${product.brand ? product.brand + " - " : ""} ${
                        product.name
                      }`}
                    />
                  </div>

                  <div className="e-w-full lg:e-w-1/2 e-flex e-items-center">
                    <Input
                      placeholder={""}
                      name={`product_${product.id}`}
                      type="number"
                      min={0}
                      max={9999}
                      onChange={(e) =>
                        updateDeviceQuantity(
                          product.id,
                          product.code,
                          isNaN(e.target.valueAsNumber)
                            ? 0
                            : e.target.valueAsNumber
                        )
                      }
                      defaultValue={null}
                      // value={getDeviceByID(product.id)?.quantity || 0}
                    />
                  </div>
                </div>
              ))}
            </div>

            <CheckboxGroup
              label="Wilt u dat Eezcit de toestellen op locatie installeert?"
              name="include_installation"
              onChange={(e) =>
                updateCalculationForm({
                  include_installation: e.target.checked,
                })
              }
              tooltip={includeInstallationTooltip?.value}
              checked={calculationForm.include_installation}
            />

            {messages.length > 0 && (
              <div className="e-rounded-md e-bg-brand-orange e-p-4">
                <div className="e-flex">
                  {/*<div className="e-flex-shrink-0">*/}
                  {/*<ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />*/}
                  {/*</div>*/}
                  <div className="e-ml-3">
                    <h3 className="e-text-sm e-font-bold e-text-white">
                      Let op
                    </h3>
                    <div className="e-mt-2 e-text-sm e-text-white">
                      <ul
                        role="list"
                        className="e-list-disc e-space-y-1 el-pl-5"
                      >
                        {messages.map((message, i) => (
                          <li key={i}>{message}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="e-col-span-12 md:e-col-span-6 e-relative e-py-4">
            <div className="e-max-w-lg e-ml-auto">
              <ShortSummary />
            </div>
          </div>
        </div>

        <div className="e-col-span-12 e-flex e-justify-end">
          <Button onClick={() => navigate("/gegevens")}>Naar gegevens</Button>
        </div>
      </Panel>
    </AppLayout>
  );
}

export default Calculator;
