import React, { createContext, useContext, useReducer } from "react";
import { ConceptQuote } from "../lib/middleware/types";
import { useMutation } from "react-query";
import { Middleware } from "../lib/middleware/middleware";
import { AppContext } from "./AppProvider";

interface CalculationContextProps {
  calculationForm: CalculationForm;
  updateCalculationForm: React.Dispatch<any>;
  customerForm: CustomerForm;
  updateCustomerForm: React.Dispatch<any>;
  conceptQuote: {
    data: ConceptQuote | null;
    isFetching: boolean;
    error: unknown;
  };
  resetForm: () => void;
}

export const CalculationContext = createContext({} as CalculationContextProps);

interface CalculationProviderProps {
  children: React.ReactNode;
}

export type CalculationFormDevice = {
  id: number;
  quantity: number;
  code: string;
};

interface CalculationForm {
  new_numbers: number;
  existing_numbers: number;
  users: number;
  mobile_users: number;
  devices: CalculationFormDevice[];
  work_from_home: boolean;
  include_installation: boolean;
}

interface FormSet {
  name: string;
  value: string;
  errors: [];
}

interface CustomerForm {
  name: string;
  email: string;
  phone: string;
  company_name: string;
}

function CalculationProvider({ children }: CalculationProviderProps) {
  const { settings } = useContext(AppContext);
  const client = new Middleware(settings.apiUrl);

  const initCalculationState = {
    new_numbers: 0,
    existing_numbers: 0,
    users: 0,
    work_from_home: true,
    devices: [],
    include_installation: false,
    mobile_users: 0,
  } satisfies CalculationForm;

  const initCustomerState = {
    name: "",
    email: "",
    phone: "",
    company_name: "",
  } satisfies CustomerForm;

  const calculate = async (payload: CalculationForm) => {
    const res = await client.calculateResource.post(payload);
    return res.data;
  };

  const { mutate, data, error, isLoading } = useMutation(calculate);

  const [calculationForm, updateCalculationForm] = useReducer(
    (prev: CalculationForm, next: CalculationForm) => {
      const data = { ...prev, ...next } satisfies CalculationForm;
      mutate(data);
      return data;
    },
    initCalculationState
  );

  const [customerForm, updateCustomerForm] = useReducer(
    (prev: CustomerForm, next: CustomerForm) => {
      return { ...prev, ...next } satisfies CustomerForm;
    },
    initCustomerState
  );

  const resetForm = () => {
    updateCalculationForm(initCalculationState);
    updateCustomerForm(initCustomerState);
  }

  return (
    <CalculationContext.Provider
      value={{
        calculationForm: calculationForm,
        updateCalculationForm: updateCalculationForm,
        customerForm: customerForm,
        updateCustomerForm: updateCustomerForm,
        conceptQuote: {
          data: data || null,
          isFetching: isLoading,
          error: error,
        },
        resetForm: resetForm,
      }}
    >
      {children}
    </CalculationContext.Provider>
  );
}

export default CalculationProvider;
