import React from "react";
import Checkbox, { CheckboxProps } from "./Checkbox";
import Label from "./Label";
import { classNames } from "../utils/classNames";

export interface CheckboxGroupProps extends CheckboxProps {
  label: string;
  className?: string;
  tooltip?: string;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  className,
  label,
  name,
  type,
  disabled = false,
  onChange,
  checked,
  tooltip,
}) => {
  return (
    <div
      className={classNames(
        "e-w-full e-flex e-flex-row e-space-x-2",
        className
      )}
    >
      <Checkbox
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        name={name}
        type={type}
      />

      <Label htmlFor={name} label={label} tooltip={tooltip} />
    </div>
  );
};

export default CheckboxGroup;
