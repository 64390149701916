import Panel from "../../components/Panel";
import React, { useContext } from "react";
import { CalculationContext } from "../../providers/CalculationProvider";
import { AppContext } from "../../providers/AppProvider";
import AppLayout from "../../layout/AppLayout";

function Success() {
  const { middleware, settings } = useContext(AppContext);
  const { customerForm, calculationForm, updateCustomerForm } =
    useContext(CalculationContext);

  return (
    <AppLayout>
      <Panel title="Bedankt voor uw aanvraag">
        <div>
          <p>We komen zo spoedig mogelijk bij u terug!</p>
        </div>
      </Panel>
    </AppLayout>
  );
}

export default Success;
