import InputGroup from "../../components/InputGroup";
import Button, { ButtonVariant } from "../../components/Button";
import Panel from "../../components/Panel";
import React, { useContext, useEffect, useState } from "react";
import { CalculationContext } from "../../providers/CalculationProvider";
import ShortSummary from "./components/ShortSummary";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../providers/AppProvider";
import { useMutation } from "react-query";
import { RequestQuotePayload } from "../../lib/middleware/types";
import AppLayout from "../../layout/AppLayout";
import axios from "axios";

interface AxiosErrorResponse {
  response: {
    data: {
      errors: object;
    };
  };
}

function Form() {
  const { middleware, settings } = useContext(AppContext);
  const { customerForm, calculationForm, updateCustomerForm, resetForm } =
    useContext(CalculationContext);
  const navigate = useNavigate();

  const submitQuote = async (payload: RequestQuotePayload) => {
    const res = await axios.post("quote", payload, {
      baseURL: settings.apiUrl,
      headers: {
        accept: "application/json",
      },
    });

    return res.data;
  };

  const { mutate, data, isLoading, error } = useMutation(submitQuote, {
    onSuccess(data) {
      navigate("/succes");
      resetForm();
    },
  });

  const onSubmit = async () => {
    mutate({
      ...customerForm,
      ...calculationForm,
    });
  };

  return (
    <AppLayout>
      <Panel title="Gegevens">
        {/* @ts-ignore */}
        {error && error?.response?.data?.errors && (
          <div className="e-grid e-grid-cols-1">
            <div className="e-rounded-md e-bg-red-50 e-p-4">
              <div className="e-flex">
                <div className="e-flex-shrink-0">
                  <svg
                    className="e-h-5 e-w-5 e-text-red-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="e-ml-3">
                  <h3 className="e-text-sm e-font-medium e-text-red-800">
                    Er is iets misgegaan met uw inzending
                  </h3>
                  <div className="e-mt-2 e-text-sm e-text-red-700">
                    <ul role="list" className="e-list-disc e-space-y-1 e-pl-5">
                      {/* @ts-ignore */}
                      {Object.values(error.response?.data.errors).map(
                        //  @ts-ignore
                        (err: string, index: number) => {
                          if (err) {
                            return <li key={`error-${index}`}>{err}</li>;
                          }
                        }
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="e-grid e-grid-cols-12 e-gap-y-8">
          <div className="e-col-span-12 sm:e-col-span-7 md:e-col-span-6 e-order-2 sm:e-order-1 e-space-y-6">
            <InputGroup
              label="Naam"
              name="name"
              placeholder="John Doe"
              type="text"
              onChange={(e) => updateCustomerForm({ name: e.target.value })}
              value={customerForm.name}
            />

            <InputGroup
              label="E-mailadres"
              name="email"
              placeholder="john@example.org"
              type="email"
              onChange={(e) => updateCustomerForm({ email: e.target.value })}
              value={customerForm.email}
            />

            <InputGroup
              label="Telefoonnummer"
              name="phone_number"
              placeholder="+31 6 123 456 78"
              type="text"
              onChange={(e) => updateCustomerForm({ phone: e.target.value })}
              value={customerForm.phone}
            />

            <InputGroup
              label="Bedrijfsnaam"
              name="company_name"
              placeholder=""
              type="text"
              onChange={(e) =>
                updateCustomerForm({ company_name: e.target.value })
              }
              value={customerForm.company_name}
            />
          </div>

          <div className="e-col-span-12 sm:e-col-span-4 md:e-col-span-5 sm:e-col-start-9 md:e-col-start-8 e-order-1 sm:e-order-2 e-relative">
            <ShortSummary />
          </div>

          <div className="e-col-span-12 e-flex e-flex-col md:e-flex-row e-justify-between e-order-3 md:e-space-x-2.5 e-space-y-4 md:e-space-y-0 e-mt-6">
            <Button variant={ButtonVariant.LINK} onClick={() => navigate("/")}>
              « Terug naar calculatietool
            </Button>

            <Button onClick={() => onSubmit()} loading={isLoading}>
              Aanvragen
            </Button>
          </div>
        </div>
      </Panel>
    </AppLayout>
  );
}

export default Form;
