import React from "react";
import { classNames } from "../utils/classNames";

export interface InputProps {
  disabled?: boolean;
  name: string;
  placeholder: string;
  type: "text" | "number" | "date" | "email" | "password" | "range";
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  min?: number;
  max?: number;
  className?: string;
  additionalProps?: any;
  defaultValue?: any;
}

const Input: React.FC<InputProps> = ({
  name,
  placeholder,
  disabled = false,
  type,
  onChange,
  value,
  className,
  min,
  max,
  ...additionalProps
}) => {
  return (
    <input
      disabled={disabled}
      placeholder={placeholder}
      className={classNames(
        type === "range"
          ? "e-w-full e-h-2 e-bg-gray-200 e-rounded-lg e-appearance-none e-cursor-pointer"
          : "",
        "e-block e-w-full e-rounded e-bg-white e-border-gray-300 e-shadow-sm focus:e-border-brand-blue-500 focus:e-ring-brand-blue-500 sm:e-text-sm placeholder:e-text-slate-300",
        "disabled:e-bg-gray-100 disabled:e-text-gray-500 disabled:e-cursor-not-allowed",
        className
      )}
      name={name}
      type={type}
      onChange={onChange}
      value={value}
      min={min}
      max={max}
      {...additionalProps}
    />
  );
};

export default Input;
