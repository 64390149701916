import React, { createContext } from "react";
import { AppConfiguration } from "../App";
import { Middleware } from "../lib/middleware/middleware";
import { APITranslation } from "../lib/middleware/types";
import { useQuery } from "react-query";

interface AppContextProps {
  settings: AppConfiguration;
  middleware: Middleware;
  translations?: APITranslation[];
}

export const AppContext = createContext({} as AppContextProps);

interface AppProviderProps {
  children: React.ReactNode;
  settings: AppConfiguration;
}

function AppProvider({ children, settings }: AppProviderProps) {
  const middleware = new Middleware(settings.apiUrl);

    const {data: translations, isFetching, error} = useQuery("translations/list", async () => {
        const res = await middleware.translationResource.list();
        return res.data;
    });

  return (
    <AppContext.Provider
      value={{
        settings,
        middleware,
        translations
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
