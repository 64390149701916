import axios, { AxiosInstance, AxiosResponse } from "axios";
import { MiddlewareClient } from "./types";

export class Client implements MiddlewareClient {
  private version: string = "1.0";
  private readonly baseURL: string;

  public client: AxiosInstance;

  constructor(baseURL: string) {
    this.baseURL = baseURL;

    this.client = this.createClient();
  }

  createClient(): AxiosInstance {
    return axios.create({
      baseURL: this.baseURL,
      headers: {
        accept: "application/json",
      },
    });
  }

  async get(path: string, params?: object): Promise<AxiosResponse> {
    return await this.client
      .get(path, {
        params: params,
      })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error) => {
        // if (error.response.data.detail) {
        //     throw new Error(error.response.data.detail)
        // }

        throw new Error("Could not get from Middleware");
      });
  }

  async post(path: string, payload?: object): Promise<AxiosResponse> {
    return await this.client
      .post(path, payload)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error) => {
        if (error.response.data) {
          throw new Error(error.response.data);
        }
        throw new Error("Could not post to Middleware");
      });
  }

  async patch(path: string, payload?: object): Promise<AxiosResponse> {
    return await this.client
      .patch(path, payload)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error) => {
        if (error.response.data) {
          throw new Error(error.response.data);
        }
        throw new Error("Could not patch to Middleware");
      });
  }

  async put(path: string, payload?: object): Promise<AxiosResponse> {
    return await this.client
      .put(path, payload)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error) => {
        if (error.response.data) {
          throw new Error(error.response.data);
        }
        throw new Error("Could not put to Middleware");
      });
  }

  async delete(path: string, payload?: object): Promise<AxiosResponse> {
    return await this.client.delete(path, payload);
  }
}
