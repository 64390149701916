import "./App.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import AppProvider from "./providers/AppProvider";
import Calculator from "./pages/quote/Calculator";
import Form from "./pages/quote/Form";
import CalculationProvider from "./providers/CalculationProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import React from "react";
import Success from "./pages/success/Success";

export type AppConfiguration = {
  apiUrl: string;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

function App() {
  const settings: AppConfiguration = {
    apiUrl: import.meta.env.VITE_API_URL,
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider settings={settings}>
        <CalculationProvider>
          <HashRouter>
            <Routes>
              <Route path="/" element={<Calculator />} />
              <Route path="/gegevens" element={<Form />} />
              <Route path="/succes" element={<Success />} />
            </Routes>
          </HashRouter>
        </CalculationProvider>
      </AppProvider>
    </QueryClientProvider>
  );
}

export default App;
