import { WithChildren, WithStyles } from "../types";
import { classNames } from "../utils/classNames";

interface PanelProps extends WithChildren, WithStyles {
  title: string;
}

function Panel({ children, title }: PanelProps) {
  return (
    <div
      className={classNames(
        "e-rounded e-shadow e-border-b-4 e-border-b-brand-orange e-py-4 e-px-6 e-space-y-6"
      )}
    >
      <h2 className="e-text-lg md:e-text-2xl e-font-bold">{title}</h2>

      <>{children}</>
    </div>
  );
}

export default Panel;
