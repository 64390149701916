import { AxiosInstance, AxiosRequestConfig } from "axios";

export type Base = {
  client: MiddlewareClient;
  productResource: ProductResource;
  calculateResource: CalculateResource;
  quoteResource: QuoteResource;
  translationResource: TranslationResource;
};

export type MiddlewareClient = {
  client: AxiosInstance;
  createClient: () => AxiosInstance;
  get: (path: string, params?: object) => Promise<any>;
  post: (
    path: string,
    data?: object,
    config?: AxiosRequestConfig | null
  ) => Promise<any>;
  patch: (
    path: string,
    data?: object,
    config?: AxiosRequestConfig | null
  ) => Promise<any>;
  put: (
    path: string,
    data?: object,
    config?: AxiosRequestConfig | null
  ) => Promise<any>;
  delete: (
    path: string,
    data?: object,
    config?: AxiosRequestConfig | null
  ) => Promise<any>;
};

export type BaseResource = {
  client: MiddlewareClient;
};

export type ProductResource = {
  list: () => Promise<ListResponse<APIProduct>>;
};

export type TranslationResource = {
  list: () => Promise<ListResponse<APITranslation>>;
};


export type CalculateResource = {
  post: (
    payload: CalculateConceptQuotePayload
  ) => Promise<PostResponse<ConceptQuote>>;
};

export type QuoteResource = {
  request: (
    payload: RequestQuotePayload
  ) => Promise<PostResponse<RequestQuoteResponse>>;
};

export interface ConceptQuote {
  one_off_sum: number;
  monthly_sum: number;
  quote_lines: ConceptQuoteLine[];
}

export enum PaymentType {
  MONTHLY = "monthly",
  ONE_OFF = "one_off",
}

export interface ConceptQuoteLine {
  description: string;
  base_price: number;
  price: number;
  quantity: number;
  type: PaymentType;
}

export interface APIProduct {
  id: number;
  name: string;
  code: string;
  brand: string;
  price: number;
  description: string;
}

export interface APITranslation {
  locale: string;
  key: string;
  value: string;
}

export interface CalculateConceptQuotePayload {
  new_numbers: number;
  existing_numbers: number;
  users: number;
  mobile_users: number;
  devices: {
    id: number;
    quantity: number;
  }[];
  work_from_home: boolean;
  include_installation: boolean;
}

export interface RequestQuotePayload extends CalculateConceptQuotePayload {
  name: string;
  company_name: string;
  email: string;
  phone_number?: string;
}

export interface RequestQuoteResponse {}

export interface ListResponse<T> {
  data: T[];
}

export interface PostResponse<T> {
  data: T;
}

export interface GetResponse<T> {
  data: T;
}
