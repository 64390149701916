import Base from "./base";
import { APIProduct, ListResponse, ProductResource } from "../types";

export default class Product extends Base implements ProductResource {
  async list(): Promise<ListResponse<APIProduct>> {
    try {
      return await this.client.get("products");
    } catch (e: any) {
      throw new Error(e);
    }
  }
}
