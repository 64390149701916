import Base from "./base";
import { ListResponse, APITranslation, TranslationResource } from "../types";

export default class Translation extends Base implements TranslationResource {
  async list(): Promise<ListResponse<APITranslation>> {
    try {
      return await this.client.get("translations");
    } catch (e: any) {
      throw new Error(e);
    }
  }
}
