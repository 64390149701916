import Base from "./base";
import {
  CalculateConceptQuotePayload,
  PostResponse,
  QuoteResource,
  RequestQuoteResponse,
} from "../types";

export default class Quote extends Base implements QuoteResource {
  async request(
    payload: CalculateConceptQuotePayload
  ): Promise<PostResponse<RequestQuoteResponse>> {
    try {
      return await this.client.post("quote", payload);
    } catch (e: any) {
      throw new Error(e);
    }
  }
}
