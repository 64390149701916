import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import button from "../components/Button";

interface AppLayoutProps {
  children: React.ReactNode;
}

const steps = [
  {
    id: "Stap 1",
    name: "Calculatietool",
    href: "/",
    status: "current",
    pathName: "/",
  },
  {
    id: "Stap 2",
    name: "Gegevens",
    href: "gegevens",
    status: "upcoming",
    pathName: "/gegevens",
  },
];

function AppLayout({ children }: AppLayoutProps) {
  const location = useLocation();

  const navigate = useNavigate();

  return (
    <div className="e-antialiased e-max-w-7xl e-mx-auto e-flex e-flex-col e-w-full e-text-brand-gray e-transition-all">
      <nav aria-label="Progress">
        <ol
          role="list"
          className="e-space-y-4 md:e-flex md:e-space-y-0 md:e-space-x-8"
        >
          {steps.map((step) => (
            <li key={step.name} className="md:e-flex-1">
              {step.pathName === location.pathname ? (
                <button
                  className="e-w-full e-group e-flex e-flex-col e-border-l-4 e-border-brand-orange e-py-2 e-pl-4 hover:e-border-brand-orange/75 md:e-border-l-0 md:e-border-t-4 md:e-pl-0 md:e-pt-4 md:e-pb-0"
                  // onClick={() => navigate(step.href)}
                >
                  <span className="e-text-sm e-font-medium e-text-brand-orange group-hover:e-text-brand-orange">
                    {step.id}
                  </span>
                  <span className="e-text-sm e-font-medium">{step.name}</span>
                </button>
              ) : (
                <button
                  onClick={() => navigate(step.href)}
                  className="e-w-full e-group e-flex e-flex-col e-border-l-4 e-border-gray-200 e-py-2 e-pl-4 md:e-border-l-0 md:e-border-t-4 md:e-pl-0 md:e-pt-4 md:e-pb-0"
                >
                  <span className="e-text-sm e-font-medium e-text-gray-500">
                    {step.id}
                  </span>
                  <span className="e-text-sm e-font-medium">{step.name}</span>
                </button>
              )}
            </li>
          ))}
        </ol>
      </nav>

      <div className="e-mt-12">{children}</div>
    </div>
  );
}

export default AppLayout;
