import Base from "./base";
import {
  CalculateConceptQuotePayload,
  CalculateResource,
  ConceptQuote,
  PostResponse,
} from "../types";

export default class Calculate extends Base implements CalculateResource {
  async post(
    payload: CalculateConceptQuotePayload
  ): Promise<PostResponse<ConceptQuote>> {
    try {
      return await this.client.post("calculate", payload);
    } catch (e: any) {
      throw new Error(e);
    }
  }
}
